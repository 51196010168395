import React from "react"
import { Layout, ILayoutProps } from "../components/Layout"
import { SEO } from "../components/Seo"
import { Link, graphql } from "gatsby";

export const query = graphql`
query NewsQuery {
    metaImage: file(relativePath: {eq: "meta.jpg"}) {
    childImageSharp {
      original{src
      }
    }
  }
}
`;

export interface INewsProps extends ILayoutProps
{
    data: any;
}

export default class PrivacyPolicy extends React.Component<INewsProps, {}>
{
    constructor(props: INewsProps)
    {
        super(props);
    }

    public render(): JSX.Element
    {
        return (
            <Layout className="homepage">
                <SEO title="News" image={this.props.data.metaImage.childImageSharp.original.src} />

                <section className="hero hero-sub-page">
                    <div className="hero-content">
                        <div className="content">
                            <h1>News</h1>
                        </div>
                    </div>
                </section>

                <section className="news-listing">
                    <div className="content news-item">
                        <article>
                            <div style={{ borderBottom: "1px solid #e6e6e6", paddingBottom: "25px" }}>
                                <h3 className="news-title">Re-opening 3rd December 2020</h3>
                                <strong>Updated: 28/11/2020</strong>
                            </div>

                            <p>
                                We are pleased to announce that we will be opening from 3rd December 2020 for dine in and collection orders.
                            </p>

                            <p>
                                We have in place a number of procedures in accordance with Government guidelines to ensure the safety
                                and wellbeing of all our customers and staff at Oriental Garden.
                            </p>

                            <ul>
                                <li>All customers and staff entering the premises will be screened with an infrared thermometer.</li>
                                <li>Customers will be seated at tables at least 1 metre apart.</li>
                                <li>Touchless sanitising stations are available for customer use.</li>
                                <li>Staff will be wearing masks and/or visors, and will wash their hands in between serving each table.</li>
                                <li>Contactless payment is highly encouraged and we accept all major credit cards.</li>
                                <li>
                                    Northampton falls into Tier 2 meaning we are only able to seat together people of the same household or support bubble.
                                </li>
                                <li>
                                    We kindly ask that customers provide their contact details for tracing purposes.
                                </li>
                            </ul>

                            <p>
                                Additionally, please note that Dim Sum will be served from 12 noon to 8pm Sunday to Friday, and Saturday served 12pm to 4.30 pm.
                            </p>

                            <p>
                                We look forward to welcoming you back!
                            </p>

                            <strong>
                                Yours sincerely,
                                Oriental Garden staff
                            </strong>
                        </article>
                    </div>

                    <div className="content news-item">
                        <article>
                            <div style={{ borderBottom: "1px solid #e6e6e6", paddingBottom: "25px" }}>
                                <h3 className="news-title">Temporarily Closed until 2nd December 2020</h3>
                                <strong>Updated: 01/11/2020</strong>
                            </div>

                            <p>
                                Due to the English national lockdown, we will be temporarily closed until 2nd December 2020,
                                or until further government advice has been issued.
                            </p>
                            <p>
                                Throughout this period, we will continue to monitor the situation closely. We thank you for your kind
                                understanding and look forward to welcoming you back soon.
                            </p>
                            <strong>
                                Yours sincerely,
                                Oriental Garden staff
                            </strong>
                        </article>
                    </div>

                    <div className="content news-item">
                        <article>
                            <div style={{ borderBottom: "1px solid #e6e6e6", paddingBottom: "25px" }}>
                                <h3 className="news-title">Temporarily Closed from 31st October to 13th November 2020 inclusive</h3>
                                <strong>Updated: 31/10/2020</strong>
                            </div>

                            <p>
                                At Oriental Garden, the well-being and safety of our customers and employees has always been our priority during this difficult time.
                            </p>
                            <p>
                                We have proactively decided to temporarily close for deep cleaning as a precautionary measure following two of our employees testing positive for Covid-19 and are in the process of contacting customers via contact tracing.
                            </p>
                            <p>
                                <strong>We will re-open on Saturday 14th November 2020</strong>. We are extremely grateful for your support and look forward to welcoming you back soon.
                            </p>
                            <strong>
                                Yours sincerely,
                                Oriental Garden staff
                            </strong>
                        </article>
                    </div>

                    <div className="content news-item">
                        <article>
                            <div style={{ borderBottom: "1px solid #e6e6e6", paddingBottom: "25px" }}>
                                <h3 className="news-title">Restaurant Re-Opening - 16th August 2020</h3>
                                <strong>Updated: 21/09/2020</strong>
                            </div>

                            <p>
                                We are pleased to announce that we will be opening from Sunday 16th August 2020 for dine in and collection orders.
                                We will be participating in the Government's Eat Out to Help Out scheme.
                            </p>

                            <p>
                                We have put in a number of procedures in accordance with Government guidelines to ensure the safety and wellbeing of all our customers and staff at Oriental Garden.
                            </p>

                            <ul>
                                <li>All customers and staff entering the premises will be screened with an infrared thermometer.</li>
                                <li>Customers will be seated at tables at least 1 metre apart.</li>
                                <li>Touchless sanitising stations are available for customer use.</li>
                                <li>Staff will be wearing masks and/or visors, and will wash their hands in between serving each table.</li>
                                <li>Contactless payment is highly encouraged and we accept all major credit cards.</li>
                                <li>
                                    A maximum of 6 members from the same household may be seated at a table, and a maximum of two households may be seated at a table.
                                    This means that a maximum of 12 people from two households can be seated at the same table.
                                </li>
                                <li>
                                    We kindly ask that customers provide their contact details for tracing purposes.
                                </li>
                            </ul>

                            <p>
                                We look forward to welcoming you back!
                            </p>

                            <strong>
                                Yours sincerely,
                                Oriental Garden staff
                            </strong>
                        </article>
                    </div>
                </section>
            </Layout >
        );
    }
}